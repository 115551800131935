import { Col, Space, Spin, SpinProps } from "antd"
import { FC } from "react"
import { useBreakpoints } from "../../hooks/useBreakpoints"

import "./Loader.less"

const Loader: FC<SpinProps> = props => {
  const { isPreMobile } = useBreakpoints()

  return (
    <Space className={"loader-wrapper"} align={"center"}>
      <Col>
        <Spin size={isPreMobile ? "small" : "large"} indicator={<span className={"loader"} />} {...props} />
      </Col>
    </Space>
  )
}

export { Loader }
