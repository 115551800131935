import { FC, JSX } from "react"
import { NavLink, useLocation } from "react-router-dom"
import { useBreakpoints } from "../../hooks/useBreakpoints"
import { useApp } from "../app"
import Navigation from "./Navigation"

interface HeaderMenuProps {
  data?: Maybe<Array<Maybe<ComponentUiLinksGroup>>>
}

type MenuItemsProps = {
  key: string
  label: string | JSX.Element
}

const HeaderMenu: FC<HeaderMenuProps> = ({ data }) => {
  const { isTablet } = useBreakpoints()
  const { pathname } = useLocation()
  const { burger } = useApp()

  function getNavigationItems(dataLinks: Maybe<ComponentUiLinksGroup>[]) {
    const getMenuItem = (item: Maybe<ComponentUiLinksGroup>) => {
      const key = item?.url ?? "/"
      const label = item?.url ? (
        <NavLink to={key} onClick={burger.toggle}>
          {item.title ?? ""}
        </NavLink>
      ) : (
        item?.title ?? ""
      )

      return { key, label }
    }

    const desktopMenu = dataLinks.map(item => {
      return {
        key: item?.url ?? "/ ",
        label: item?.url ? (
          <NavLink to={item.url ?? "/"} onClick={burger.toggle}>
            {item.title ?? ""}
          </NavLink>
        ) : (
          item?.title ?? ""
        ),
        children:
          item?.link?.map(child => ({
            key: child?.url ?? "/",
            label: (
              <NavLink to={child?.url ?? "/"} target={`_${child?.target}` ?? "_self"} onClick={burger.toggle}>
                {child?.title ?? ""}
              </NavLink>
            ),
          })) ?? null,
      }
    })

    const tabletMenu: MenuItemsProps[] = []

    dataLinks.forEach(value => {
      if (value?.link && value.link.length > 0) {
        value.link.forEach(item => {
          tabletMenu.push(getMenuItem(item))
        })
      } else {
        tabletMenu.push(getMenuItem(value))
      }
    })

    return isTablet ? tabletMenu : desktopMenu
  }

  if (!data) {
    return null
  }

  return (
    <Navigation
      mode={"horizontal"}
      disabledOverflow={true}
      selectable={true}
      defaultActiveFirst
      selectedKeys={[`${pathname}`]}
      items={getNavigationItems(data)}
    />
  )
}
export { HeaderMenu }
