import { ThemeConfig } from "antd/es/config-provider"

const theme: ThemeConfig = {
  inherit: false,
  components: {
    Carousel: {
      dotWidth: 10,
      dotActiveWidth: 10,
      dotHeight: 10,
    },
    Spin: {
      dotSizeLG: 100,
      dotSizeSM: 50,
    },
    Menu: {
      colorPrimary: "#30B783",
      horizontalItemHoverColor: "#30B783",
      horizontalItemSelectedColor: "#30B783",
      darkItemSelectedBg: "#30B783",
      padding: 0,
      fontSize: 18,
      lineHeight: 24,
      colorBgLayout: "transparent",
      colorBgContainer: "transparent",
      itemPaddingInline: 20,
      itemColor: "#6E6F73",
      itemSelectedColor: "#30B783",
      colorBorder: "#30B783",
      colorBorderBg: "#30B783",
      colorPrimaryBg: "#30B783",
      subMenuItemBg: "transparent",
      itemSelectedBg: "transparent",
      itemHoverBg: "none",
      itemHoverColor: "#30B783",
    },
    Drawer: {
      colorBgElevated: "#090C0D",
    },
    Layout: {
      colorBgLayout: "#090C0D",
      bodyBg: "#090C0D",
      colorPrimaryActive: "#30B783",
      colorPrimary: "#30B783",
      colorPrimaryTextActive: "#30B783",
      footerPadding: 0,
      headerBg: "#090C0D",
      footerBg: "#090C0D",
      headerHeight: undefined,
    },
    Button: {
      colorPrimaryActive: "#30B783",
      colorPrimaryHover: "#03DCA2",
      primaryColor: "#030e0f",
    },
  },
  token: {
    fontSize: 16,
    colorText: "#FFFFFF",
    colorTextSecondary: "#FFFFFF",
    fontSizeHeading1: 90,
    fontSizeHeading2: 64,
    fontSizeHeading3: 42,
    fontSizeHeading4: 24,
    fontSizeHeading5: 12,
  },
}

export default theme
