import { FC, SVGProps } from "react"

import { ReactComponent as CarouselArrowLeft } from "./images/ArrowLeft.svg"
import { ReactComponent as Hamburger } from "./images/Hamburger.svg"
import { ReactComponent as LinkIn } from "./images/LinkedIn.svg"
import { ReactComponent as CarouselArrowRight } from "./images/carouselArrowRigth.svg"
import { ReactComponent as Lang } from "./images/lang.svg"
import { ReactComponent as RectHover } from "./images/rect-hover.svg"
import { ReactComponent as Rect } from "./images/rect.svg"

export type IconTypes =
  | "carouselArrowRight"
  | "carouselArrowLeft"
  | "hamburger"
  | "lang"
  | "linkin"
  | "rect"
  | "hoverRect"
  | string

const icons: {
  [key: string]: FC<SVGProps<SVGSVGElement>>
} = {
  carouselArrowLeft: CarouselArrowLeft,
  carouselArrowRight: CarouselArrowRight,
  hamburger: Hamburger,
  lang: Lang,
  linkin: LinkIn,
  rect: Rect,
  hoverRect: RectHover,
} as const

type SvgIconProps = SVGProps<SVGSVGElement> & { type: IconTypes }

const SvgIcon: FC<SvgIconProps> = ({ type, ...svgProps }) => {
  const Icon = icons[type] ?? null
  return Icon && <Icon {...svgProps} />
}

export { SvgIcon }
