import { Col, List, Row, Space, Typography } from "antd"
import React, { FC } from "react"
import { useMediaQuery } from "react-responsive"
import { NavLink, useNavigate } from "react-router-dom"
import { Container } from "src/components/ui"

import "./index.less"

interface FooterProps {
  links?: Maybe<Array<Maybe<ComponentUiLinksGroup>>>
  logo?: Maybe<FileFragment>
  contact?: Maybe<ContactsGroupFragment>
}

const Footer: FC<FooterProps> = ({ links, logo, contact }) => {
  const footer = useMediaQuery({ maxWidth: 910 })
  const navigate = useNavigate()

  const date = new Date().getFullYear()
  return (
    <Container className={"footer-content"}>
      <Row wrap={false} className={"footer-navigation"}>
        <Col className={"navigation-lists"} span={footer ? 24 : 14}>
          {links &&
            links?.map(item => {
              return (
                <Space key={item?.id} direction={"vertical"}>
                  <Typography.Title className={"group-title"} level={4}>
                    {item?.title}
                  </Typography.Title>
                  {item && (
                    <List
                      dataSource={item.link ?? []}
                      className={"navigation-list"}
                      renderItem={link => {
                        return (
                          <List.Item>
                            <NavLink to={link?.url ?? ""}>{link?.title}</NavLink>
                          </List.Item>
                        )
                      }}
                    />
                  )}
                </Space>
              )
            })}
        </Col>
        <Row>
          <Col className={"contact-list"} span={footer ? 6 : 8}>
            <Space direction={"vertical"}>
              <Typography.Title level={4}>{contact?.title}</Typography.Title>
            </Space>
            {contact?.item &&
              contact.item?.map((item, index) => {
                return (
                  <div className={"contact-col"} key={index}>
                    <img
                      src={item?.image?.data?.attributes?.url ?? ""}
                      alt={item?.image?.data?.attributes?.alternativeText ?? ""}
                    />
                    {item?.url ? (
                      <NavLink to={item?.url ?? ""}>
                        <Typography.Text>{item?.title}</Typography.Text>
                      </NavLink>
                    ) : (
                      <Typography.Text>{item?.title}</Typography.Text>
                    )}
                  </div>
                )
              })}
          </Col>
        </Row>
      </Row>

      <Row className={"footer-bottom-content"}>
        {logo && (
          <div className={"footer-bottom-logo"}>
            <img src={logo.attributes?.url} alt={"logo"} onClick={() => navigate("/pages/home")} />
            <Typography.Text>{`© ${date} noxe`}</Typography.Text>
          </div>
        )}
      </Row>
    </Container>
  )
}

export { Footer }
