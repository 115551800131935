import { useEffect, useState } from "react"

type ScrollDirectionType = "down" | "up"

const SCROLL_THRESHOLD = 10

function useScrollDirection() {
  const [scrollDirection, setScrollDirection] = useState<ScrollDirectionType>("down")

  useEffect(() => {
    let lastScrollY = window.scrollY
    let isScrollingUpFromAnchor = false

    const updateScrollDirection = () => {
      requestAnimationFrame(() => {
        const scrollY = window.scrollY

        if (scrollY > lastScrollY + SCROLL_THRESHOLD) {
          setScrollDirection("down")
          isScrollingUpFromAnchor = false
        } else if (scrollY < lastScrollY - SCROLL_THRESHOLD) {
          setScrollDirection("up")
          if (!isScrollingUpFromAnchor && scrollY > SCROLL_THRESHOLD) {
            setScrollDirection("down")
          }
          isScrollingUpFromAnchor = true
        }

        if (scrollY <= 0) {
          setScrollDirection("down")
          isScrollingUpFromAnchor = false
        }

        lastScrollY = scrollY > 0 ? scrollY : 0
      })
    }

    window.addEventListener("scroll", updateScrollDirection)

    return () => {
      window.removeEventListener("scroll", updateScrollDirection)
    }
  }, [])

  return { scrollDirection }
}

export { useScrollDirection }
