import { CSSProperties, FC, MutableRefObject, PropsWithChildren } from "react"

import "./index.less"

const Container: FC<
  PropsWithChildren<{
    styles?: CSSProperties
    className?: string
    id?: string
    ref?: MutableRefObject<HTMLDivElement | null>
  }>
> = ({ styles, className, children, id, ref }) => {
  return (
    <div className={"container-wrapper"} id={id ?? ""} ref={ref}>
      <div className={`container ${className}`} style={{ ...styles }}>
        {children}
      </div>
    </div>
  )
}

export { Container }
