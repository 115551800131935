import { FC, lazy } from "react"
import { RouteObject } from "react-router-dom"

import DefaultLayout, { Loader } from "../components/layout"

const Home = lazy<FC>(() => import("./home"))
const DynamicPage = lazy<FC>(() => import("./dynamic"))
const ContactPage = lazy<FC>(() => import("./contact"))
const CookiePolicyPage = lazy<FC>(() => import("./cookie"))
const TermsConditionPage = lazy<FC>(() => import("./terms"))
const PrivacyPolicyPage = lazy<FC>(() => import("./privacy"))
const NoConnectionPage = lazy<FC>(() => import("./no-connection"))
const NotFoundPage = lazy<FC>(() => import("./not-found"))

const routes: RouteObject[] = [
  {
    Component: DefaultLayout,
    loader: () => <Loader />,
    children: [
      {
        Component: Home,
        path: "",
        index: true,
        loader: () => <Loader />,
      },
      {
        Component: ContactPage,
        path: "/contact",
        loader: () => <Loader />,
      },
      {
        Component: CookiePolicyPage,
        path: "/cookie",
        loader: () => <Loader />,
      },
      {
        Component: TermsConditionPage,
        path: "/terms",
        loader: () => <Loader />,
      },
      {
        Component: PrivacyPolicyPage,
        path: "/privacy",
        loader: () => <Loader />,
      },
      {
        Component: DynamicPage,
        path: "/pages/:slug",
        loader: () => <Loader />,
      },
      {
        Component: Home,
        path: "/pages/home",
        loader: () => <Loader />,
      },
      {
        Component: NoConnectionPage,
        path: "/no-connection",
      },
      {
        Component: NotFoundPage,
        path: "*",
      },
    ],
  },
]

export default routes
